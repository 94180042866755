import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "LiquidityPools",
    title: "Liquidity Pool",
    type: "item",
    icon: <Icon.Layers size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/liquidity/LiquidityPools",
  },
  {
    id: "FactoryConfig",
    title: "Factory Config",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/config/Factory",
  }    
]

export default horizontalMenuConfig
