const initialState = {
  connected: false,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case "WALLET_CONNECTED":
      return {
        ...state,
        account: action.account,
        connected: true,
      };
    case "KAIKAS_CONNECTED":
      return {
        ...state,
        account: action.account,
        kaikasConnected: true,
      };
    case "WALLET_DISCONNECTED":
      return {
        ...state,
        account: {},
        connected: false,
      };
    case "KAIKAS_DISCONNECTED":
      return {
        ...state,
        account: undefined,
        kaikasConnected: false,
      };
    default:
      return { ...state };
  }
};

export default walletReducer;
