import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "PriceBoard",
    title: "Price Board",
    type: "item",
    icon: <Icon.Award size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/price-board",
  },
  {
    id: "ContractTransfer",
    title: "Bridge OUT Klaytn Contract",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin"],
    navLink: "/contractTransfer",
  },
  {
    id: "LiquidityPool",
    title: "Liquidity Pool",
    type: "collapse",
    icon: <Icon.Layers size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/liquidity",
    children: [
      {
        id: "LiquidityPoolList",
        title: "List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/liquidity/LiquidityPools",
      },
    ],
  },
  {
    id: "Farm",
    title: "Farm",
    type: "collapse",
    icon: <Icon.Truck size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/farm",
    children: [
      {
        id: "FarmList",
        title: "List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/farm/FarmList",
      },
    ],
  },
  {
    id: "Syrup",
    title: "Syrup Pool",
    type: "collapse",
    icon: <Icon.Truck size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/syrup",
    children: [
      {
        id: "NewSyrup",
        title: "New Syrup",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/syrup/New",
      },
      {
        id: "SyrupList",
        title: "List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/syrup/List",
      },
    ],
  },
  {
    id: "TokenList",
    title: "Token List",
    type: "item",
    icon: <Icon.Award size={10} />,
    permissions: ["admin", "editor"],
    navLink: "/token",
  },
  {
    id: "BridgeAdmin",
    title: "Bridge Admin",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "BridgeTxSix",
        title: "Six Bridge Tx",
        type: "item",
        icon: <Icon.File size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/bridge-tx-six",
      },
      {
        id: "BridgeTxFinix",
        title: "Finix Bridge Tx",
        type: "item",
        icon: <Icon.File size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/bridge-tx-finix",
      },
    ],
  },
  {
    id: "PendingTx",
    title: "Pending Transaction",
    type: "item",
    icon: <Icon.FileMinus size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/pendingTx",
  },
  {
    id: "CaptionText",
    title: "Caption Text",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/caption-text",
  },
  {
    id: "TimeLock",
    title: "Timelock Control",
    type: "item",
    icon: <Icon.Lock size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/timelock",
  },
  {
    id: "Config",
    title: "Configiration",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/config",
    children: [
      {
        id: "FactoryConfig",
        title: "Factory Config",
        type: "item",
        icon: <Icon.Package size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/config/Factory",
      },
    ],
  },
  
  {
    id: "NFT",
    title: "NFT TOOL",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "ConfigManager",
        title: "Config Manager",
        type: "item",
        icon: <Icon.File size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/nft-config-manager",
      },
      {
        id: "NFTMetadata",
        title: "Metadata viewer",
        type: "item",
        icon: <Icon.Award size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/nft-tool",
      },
    ],
  },
];

export default navigationConfig;
