const initialState = {
  isOpen: false,
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BASIC_MODAL_TOGGLE":
      return {
        ...state,
        title: action.title,
        detail: action.detail,
        onClose: action.onClose,
        noFooter: action.noFooter,
        isOpen: true,
      }
    case "MODAL_CLOSE":
      return {
        ...state,
        isOpen: false,
      }
    default:
      return {...state}
  }
}

export default modalReducer
