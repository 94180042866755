import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import walletReducer from "./wallet/"
import modalReducer from "./modal/"
import sweetReducer from "./sweetalert/"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  wallet: walletReducer,
  modal: modalReducer,
  sweet: sweetReducer,
})

export default rootReducer
