import React from "react"
import { connect } from 'react-redux';
import VerticalLayout from "../../layouts/VerticalLayout"
import FullLayout from "../../layouts/FullpageLayout"
import HorizontalLayout from "../../layouts/HorizontalLayout"
import themeConfig from "../../configs/themeConfig"
import SweetAlert from 'react-bootstrap-sweetalert'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,} from 'reactstrap'
const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  horizontal: HorizontalLayout
}

const ContextLayout = React.createContext()

class LayoutBasic extends React.Component {
  state = {
    activeLayout: themeConfig.layout,
    width: window.innerWidth,
    lastLayout: null,
    direction: themeConfig.direction
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  handleWindowResize = () => {
    this.updateWidth()
    if (this.state.activeLayout === "horizontal" && this.state.width <= 1199) {
      this.setState({
        activeLayout: "vertical",
        lastLayout: "horizontal"
      })
    }

    if (this.state.lastLayout === "horizontal" && this.state.width >= 1199) {
      this.setState({
        activeLayout: "horizontal",
        lastLayout: "vertical"
      })
    }
  }

  componentDidMount = () => {
    if (window !== "undefined") {
      window.addEventListener("resize", this.handleWindowResize)
    }
    this.handleDirUpdate()
    if (this.state.activeLayout === "horizontal" && this.state.width <= 1199) {
      this.setState({
        activeLayout: "vertical"
      })
    } else if (
      themeConfig.layout === "horizontal" &&
      this.state.width >= 1200
    ) {
      this.setState({
        activeLayout: "horizontal"
      })
    } else {
      this.setState({
        activeLayout: "vertical"
      })
    }
  }

  componentDidUpdate() {
    this.handleDirUpdate()
  }

  handleDirUpdate = () => {
    let dir = this.state.direction
    if (dir === "rtl")
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
    else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
  }

  closeModalWithAction = () => {
    if(this.props.onClose) {
      this.props.onClose()
    }
    this.props.closeModal()
  }

  render() {
    const { children } = this.props
    // console.log(this.props)
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts["full"],
          VerticalLayout: layouts["vertical"],
          horizontalLayout: layouts["horizontal"],
          switchLayout: layout => {
            this.setState({ activeLayout: layout })
          },
          switchDir: dir => {
            this.setState({ direction: dir })
          }
        }}
      >
        {children}
        <SweetAlert 
            error={this.props.sweet.alertType === "error" ? true : false } 
            success={this.props.sweet.alertType === "success" ? true : false } 
            info={this.props.sweet.alertType === "info" ? true : false } 
            title={this.props.sweet.title}
            show={this.props.sweet.show} 
            onConfirm={()=>{
              this.props.sweet.onClose()
              this.props.closeAlert()
            }}
        >
            <p className="sweet-alert-text">
              {this.props.sweet.message}
            </p>
        </SweetAlert>
        <Modal
          isOpen={this.props.modalOpen}
          // className={this.props.className}
        >
          <ModalHeader>
            {this.props.modalTitle}
          </ModalHeader>
          <ModalBody>
            {this.props.modalDetail}
          </ModalBody>
          {this.props.modalNoFooter? (<div/>) : (<ModalFooter>
            <Button color="primary" onClick={this.closeModalWithAction}>
              Accept
            </Button>{" "}
          </ModalFooter>)}
          
        </Modal>
      </ContextLayout.Provider>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    modalOpen: state.modal.isOpen,
    modalTitle: state.modal.title,
    modalDetail: state.modal.detail,
    modalNoFooter: state.modal.noFooter,
    onClose : state.modal.onClose,
    sweet: state.sweet
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    basicModal: (title,message,onClose) => dispatch({ type: 'BASIC_MODAL_TOGGLE', title: title, detail : message,onClose: onClose }),
    closeModal: () => dispatch({ type: 'MODAL_CLOSE'}),
    sweetAlert: (title,alertType,message,onClose) => dispatch({ type: 'SWEET_ALERT', title: title, alertType: alertType,message:message,onClose:onClose}),
    closeAlert: () => dispatch({ type: 'SWEET_CLOSE' }),
  }
}

const Layout = connect(mapStateToProps,mapDispatchToProps)(LayoutBasic)


export { Layout, ContextLayout }
