const initialState = {
    title: "",
    show : false,
    alertType : "",
    message : "alert message",
    onClose : ()=>{},
}

const sweetReducer = (state = initialState, action) => {
  console.log("action",action)
  switch (action.type) {
    case "SWEET_ALERT":
      return {
        ...state,
        title: action.title,
        alertType: action.alertType,
        message: action.message,
        onClose: action.onClose,
        show: true,
      }
    case "SWEET_CLOSE":
      return {
        ...state,
        show: false,
      }
    default:
      return {...state}
  }
}

export default sweetReducer
